export let menuItems: MenuItem[] = [
    {
        label: 'Admin',
        routerLink: 'admin',
        disabled: false,
        shown: false,
        comingSoon: false,
        requiredPermission: 'admin_can_view_admin',
        icon: '',
        showInTopMenu: true,
    },
    {
        label: 'Home',
        routerLink: 'home',
        disabled: false,
        shown: true,
        comingSoon: false,
        requiredPermission: 'home_can_view_home',
        icon: 'home',
        showInTopMenu: true,
    },
    {
        label: 'Cockpit',
        routerLink: 'cockpit',
        disabled: false,
        shown: true,
        comingSoon: false,
        requiredPermission: 'cockpit_can_view_cockpit',
        icon: 'dashboard',
        showInTopMenu: true,
    },
    {
        label: 'Resultate',
        routerLink: 'results',
        disabled: false,
        shown: true,
        comingSoon: false,
        requiredPermission: 'results_can_view_all_results',
        alternativePermissions: [
            'results_can_view_role_results',
            'results_can_view_role_results_master',
            'results_can_view_own_results',
        ],
        icon: 'more_horiz',
        showInTopMenu: true,
    },
    {
        label: 'Assessment',
        routerLink: 'assessment',
        disabled: false,
        shown: true,
        comingSoon: false,
        requiredPermission: 'assessment_can_view_assessment',
        icon: 'format_list_bulleted',
        showInTopMenu: true,
    },
    {
        label: 'Risiken',
        routerLink: 'risks',
        disabled: false,
        shown: true,
        comingSoon: false,
        requiredPermission: 'risks_can_view_risks',
        icon: 'local_fire_department',
        showInTopMenu: true,
    },
    {
        label: 'Massnahmen',
        routerLink: 'measures',
        disabled: false,
        shown: true,
        comingSoon: false,
        requiredPermission: 'measures_can_view_measures',
        icon: 'priority_high',
        showInTopMenu: true,
    },
    {
        label: 'Journal',
        routerLink: 'journals',
        disabled: false,
        shown: true,
        comingSoon: false,
        requiredPermission: 'journals_can_view_journals',
        icon: 'edit_note',
        showInTopMenu: true,
    },
    {
        label: 'Dokumente',
        routerLink: 'documents',
        disabled: false,
        shown: true,
        comingSoon: false,
        requiredPermission: 'documents_can_view_documents',
        icon: 'description',
        showInTopMenu: true,
    },
    {
        label: 'User',
        routerLink: 'users',
        disabled: false,
        shown: true,
        comingSoon: false,
        requiredPermission: 'admin_can_view_admin',
        icon: 'groups',
        showInTopMenu: false,
    },
    {
        label: 'Assessment organisieren',
        routerLink: 'wizard',
        disabled: false,
        shown: true,
        comingSoon: false,
        requiredPermission: 'admin_can_view_admin',
        icon: 'format_list_bulleted_add',
        showInTopMenu: false,
    },
    {
        label: 'Assessment verwalten',
        routerLink: 'assessment-submissions',
        disabled: false,
        shown: true,
        comingSoon: false,
        requiredPermission: 'admin_can_view_admin',
        icon: 'format_list_bulleted',
        secondaryIcon: 'settings',
        showInTopMenu: false,
    },
]

export class MenuItem {
    label: string
    routerLink: string
    disabled: boolean
    shown: boolean
    comingSoon: boolean
    requiredPermission: string
    alternativePermissions?: string[]
    icon: string
    secondaryIcon?: string | undefined
    showInTopMenu: boolean = true
}
