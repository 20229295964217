import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'

import { HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser'
import * as Hammer from 'hammerjs'

import { environment } from 'src/environments/environment'

import { Router } from '@angular/router'
import { AuthConfig, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc'
import { authCodeFlowConfig, devAuthCodeFlowConfig } from './modules/core/services/oauth.config'
import { StatehandlerService, StatehandlerServiceImpl } from './modules/core/services/statehandler.service'
import {
    StatehandlerProcessorService,
    StatehandlerProcessorServiceImpl,
} from './modules/core/services/statehandler-processor.service'
import { AuthStorageService } from './modules/core/services/auth-storage.service'
import { JwtInterceptor } from './modules/core/helpers/error.interceptor'
import * as Sentry from '@sentry/angular-ivy'

declare const VERSION: string

class CustomLoader implements TranslateLoader {
    constructor(private http: HttpClient) {}

    getTranslation(lang: string) {
        const path = `/assets/i18n/${lang}.json`
        return this.http.get(environment.production ? `${path}?v=${VERSION}` : path)
    }
}

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
    override = <any>{
        swipe: { direction: Hammer.DIRECTION_ALL },
    }
}

const stateHandlerFn = (stateHandler: StatehandlerService) => {
    return () => {
        return stateHandler.initStateHandler()
    }
}

const ALLOWED_URLS_PROD = [
    'https://login.minimaltools.ch/admin/v1',
    'https://login.minimaltools.ch/management/v1',
    'https://login.minimaltools.ch/auth/v1/',
]

const ALLOWED_URLS_PREPROD = [
    'https://minimaltoolstest-aokiyi.zitadel.cloud/admin/v1',
    'https://minimaltoolstest-aokiyi.zitadel.cloud/management/v1',
    'https://minimaltoolstest-aokiyi.zitadel.cloud/auth/v1/',
]

const isProd = window.location.hostname.includes('app.minimaltools.ch')
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: CustomLoader,
                deps: [HttpClient],
            },
        }),

        HammerModule,
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: [...(isProd ? ALLOWED_URLS_PROD : ALLOWED_URLS_PREPROD), '/v1/'],
                sendAccessToken: true,
            },
        }),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: stateHandlerFn,
            multi: true,
            deps: [StatehandlerService],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
        {
            provide: AuthConfig,
            useValue: isProd ? authCodeFlowConfig : devAuthCodeFlowConfig,
        },
        {
            provide: StatehandlerProcessorService,
            useClass: StatehandlerProcessorServiceImpl,
        },
        {
            provide: StatehandlerService,
            useClass: StatehandlerServiceImpl,
        },
        {
            provide: OAuthStorage,
            useClass: AuthStorageService,
        },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
