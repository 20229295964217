import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import * as Sentry from '@sentry/angular-ivy'
import mixpanel from 'mixpanel-browser'

import 'hammerjs'

if (environment.production) {
    enableProdMode()
    Sentry.init({
        dsn: 'https://fecd6782dab2304f95eb1af246a82e5b@o4506948440555520.ingest.us.sentry.io/4506948443242496',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: true,
                blockAllMedia: true,
            }),
        ],
        tracesSampleRate: 1.0,
        maxValueLength: 1000,
        tracePropagationTargets: ['localhost', 'https://app.minimaltools.ch/' + environment.apiUrl],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        release: environment.release,
        environment: environment.environment,
    })
}

mixpanel.init('52e6c16cbac77bf5d50de6009d611972', {
    debug: true,
    track_pageview: true,
    persistence: 'localStorage',
})

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err))
